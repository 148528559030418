import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Vitals Details")])]),_c(VCardText,[_c(VRow,[_vm._l((_vm.vitalOptions),function(value,index){return [(index != 'BloodSugarComments')?_c(VCol,{key:index,attrs:{"cols":"12","sm":"12","xs":"12","md":"12","lg":"6","xl":"4"}},[_c(VTextField,{attrs:{"type":"input","dense":"","label":index,"hint":_vm.vitalUnit[index],"readonly":!_vm.isEdit},model:{value:(_vm.vitalsData[index]),callback:function ($$v) {_vm.$set(_vm.vitalsData, index, $$v)},expression:"vitalsData[index]"}},[_vm._v(" "+_vm._s(_vm.vitalUnit[index])+" ")])],1):_c(VCol,{key:index,attrs:{"cols":"12","sm":"12","xs":"12","md":"12","lg":"6","xl":"4"}},[_c(VTextarea,{attrs:{"dense":"","label":index,"outlined":"","hint":_vm.vitalUnit[index],"readonly":!_vm.isEdit},model:{value:(_vm.vitalsData[index]),callback:function ($$v) {_vm.$set(_vm.vitalsData, index, $$v)},expression:"vitalsData[index]"}},[_vm._v(" "+_vm._s(_vm.vitalUnit[index])+" ")])],1)]})],2)],1)],1),_c('single-confirmation',{attrs:{"selected-item":_vm.selectedItem},on:{"confirmation":function($event){_vm.isConfirmDialogActive = false}},model:{value:(_vm.isConfirmDialogActive),callback:function ($$v) {_vm.isConfirmDialogActive=$$v},expression:"isConfirmDialogActive"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }