<template>
  <div>
    <div>
      <v-row justify="center">
        <v-dialog
          v-model="isTuberculosisFormActive"
          persistent
          max-width="50%"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Tuberculosis Details</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="immunizationForm"
                @submit="validate"
              >
                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-text-field
                      v-model="patientTuberculosisDTO.manufacturer"
                      :readonly="!isEdit"
                      label="Manufacturer"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-menu
                      ref="menuref"
                      v-model="menuID"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :readonly="!isEdit"
                    >
                      <template
                        v-slot:activator="{ on, attrs }"
                      >
                        <v-text-field
                          v-model="patientTuberculosisDTO.expirationDate"
                          :prepend-icon="icons.mdiCalendar"
                          :readonly="!isEdit"
                          label="Expiration Date"
                          hint="YYYY-MM-DD"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="patientTuberculosisDTO.expirationDate"
                        :readonly="!isEdit"
                        :show-current="true"
                        color="primary"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-text-field
                      v-model="patientTuberculosisDTO.lotNumber"
                      :readonly="!isEdit"
                      label="Lot Number"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <v-text-field
                      v-model="patientTuberculosisDTO.administrationSite"
                      :readonly="!isEdit"
                      label="Administrations Site"
                      hint="Right Shoulder"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="6"
                  >
                  <v-textarea
                    outlined
                    v-model="patientTuberculosisDTO.notes"
                    :readonly="!isEdit"
                    label="Notes"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <div
                  v-if="patientTuberculosisDTO.administered"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      lg="6"
                    >
                      <v-switch
                      v-if="isEdit"
                      v-model="patientTuberculosisDTO.read"
                      color="primary"
                      :readonly="!isEdit"
                      >
                        <template v-slot:label>
                          {{patientTuberculosisDTO.read ? "Read" : "Not Read"}}
                        </template>
                      </v-switch>
                    </v-col>
                  </v-row>

                  <div
                    v-if="patientTuberculosisDTO.read"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        lg="6"
                      >
                        <v-switch
                        v-if="isEdit"
                        v-model="patientTuberculosisDTO.positive"
                        color="primary"
                        :readonly="!isEdit"
                        >
                          <template v-slot:label>
                            {{patientTuberculosisDTO.positive ? "Positive PPD" : "Negative PPD"}}
                          </template>
                        </v-switch>
                      </v-col>

                      <v-col
                        cols="12"
                        lg="6"
                      >
                        <v-text-field
                          v-model="patientTuberculosisDTO.induration"
                          :readonly="!isEdit"
                          label="Results in mm"
                          hint="2"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12"
                        lg="6"
                      >
                        <v-switch
                        v-if="isEdit"
                        v-model="patientTuberculosisDTO.positiveHistory"
                        color="primary"
                        :readonly="!isEdit"
                        >
                          <template v-slot:label>
                            {{patientTuberculosisDTO.positiveHistory ? "No History of TB" : "History of TB"}}
                          </template>
                        </v-switch>
                      </v-col>
                    </v-row>

                    <div
                      v-if="patientTuberculosisDTO.positiveHistory"
                    >
                      <v-row>
                        <v-col
                        cols="12"
                        lg="6"
                        >
                          <v-menu
                            ref="menuref"
                            v-model="menuLO"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template
                              v-slot:activator="{ on, attrs }"
                            >
                              <v-text-field
                                v-model="patientTuberculosisDTO.positiveDate"
                                :prepend-icon="icons.mdiCalendar"
                                :readonly="!isEdit"
                                label="Positive PPD Date"
                                hint="YYYY-MM-DD"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="patientTuberculosisDTO.positiveDate"
                              :readonly="!isEdit"
                              :show-current="true"
                              color="primary"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="patientTuberculosisDTO.positiveAdministrationSite"
                            :readonly="!isEdit"
                            label="Administrations Site for Positive PPD"
                            hint="Right Shoulder"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="patientTuberculosisDTO.medicationsGiven"
                            :readonly="!isEdit"
                            label="Medications Given"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <v-text-field
                            v-model="patientTuberculosisDTO.medicationDuration"
                            :readonly="!isEdit"
                            label="How long were Medications Taken"
                            hint="2 Weeks"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>

                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      color="error"
                      type="reset"
                      text
                      @click="closeForm()"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      v-if="isEdit"
                      color="primary"
                      text
                      @click="submitForm()"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import formService from '@/services/FormService'
import useFormList from '@/store/useFormService'
import moment from 'moment'
import CommonList from '@/store/CommonService'

export default {
  model: {
    prop: 'isTuberculosisFormActive',
    event: 'update:is-immunization-form-active',
  },
  props: {
    isTuberculosisFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    patientTuberculosisDTO: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    console.log(props.patientTuberculosisDTO)

    const immunizationForm = ref(null)
    const menuID = ref(false)
    const menuLO = ref(false)
    const menuref = ref(null)

    const validate = patientTuberculosisDTO => {
      if (immunizationForm.value.validate(patientTuberculosisDTO)) {
        return true
      }

      return false
    }

    const save = d => {
      menuref.value.save(d)
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update:is-immunization-form-active', false)
    }

    const { formatDate } = CommonList()

    const FORM_APP = 'app-form'

    // Register module
    if (!store.hasModule(FORM_APP)) {
      store.registerModule(FORM_APP, formService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP)) {
        store.unregisterModule(FORM_APP)
      }
    })

    const { postPatientTBForm } = useFormList()

    return {
      immunizationForm,
      validate,
      closeForm,
      refreshParent,

      postPatientTBForm,

      required,
      menuID,
      menuLO,
      menuref,
      save,

      formatDate,

      icons: {
        mdiClose,
      },
    }
  },
  created(){
    if(this.patientTuberculosisDTO.id !== undefined) {
      this.patientTuberculosisDTO.expirationDate = this.formatDate(this.patientTuberculosisDTO.expirationDate)
    }
    else{
      this.patientTuberculosisDTO.isActive = true
      this.patientTuberculosisDTO.expirationDate = this.formatDate(new Date())
    }
  },
  methods: {
    submitForm() {
      if (this.validate(this.patientTuberculosisDTO)) {
        console.log('posting', this.patientTuberculosisDTO)
        this.patientTuberculosisDTO.administered = true
        this.postPatientTBForm(this.patientTuberculosisDTO)

        this.closeForm()
        this.refreshParent()
      } else {
        console.log('Not Valid')
      }
    },
  },
}
</script>
