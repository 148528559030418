<template>
  <div>
    <div>
      <v-row justify="center">
        <v-dialog
          v-model="isProblemFormActive"
          persistent
          max-width="50%"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Problem Details</span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="problemForm"
                @submit="validate"
              >
                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <problem-select
                      v-model="patientProblemDTO.problemDescriptionCode"
                      color="primary"
                      label="Problem Description"
                      :multiple="false"
                      :problem="patientProblemDTO.problem"
                      :readonly="!isEdit"
                      :rules="[required]"
                      @problem-select="updateProblem"
                    >
                    </problem-select>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="2"
                  >
                    <v-text-field
                      v-model="patientProblemDTO.problemPriority"
                      type="number"
                      label="Priority"
                      hint="1-16"
                      :readonly="!isEdit"
                      :rules="[required]"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="4"
                  >
                    <v-radio-group
                      v-model="patientProblemDTO.problemPersistence"
                      row
                      :readonly="!isEdit"
                    >
                      <v-radio
                        label="Acute"
                        value="acute"
                      ></v-radio>
                      <v-radio
                        label="Chronic"
                        value="chronic"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>

                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <severity-select
                      v-model="patientProblemDTO.problemSeverityId"
                      label="Problem Severity"
                      hint="Mild"
                      :severity="patientProblemDTO.problemSeverity"
                      :readonly="!isEdit"
                      :rules="[required]"
                      @severity-select="updateSeverity"
                    ></severity-select>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                    <verification-select
                      v-model="patientProblemDTO.conditionVerificationId"
                      label="Problem Verification"
                      hint="Confirmed"
                      :verification="patientProblemDTO.conditionVerification"
                      :readonly="!isEdit"
                      :rules="[required]"
                      @verification-select="updateVerification"
                    ></verification-select>
                  </v-col>
                </v-row>

                <v-row>

                  <v-col
                    cols="12"
                  >
                  <v-radio-group
                      v-model="patientProblemDTO.problemClassification"
                      row
                      :readonly="!isEdit"
                    >
                      <template v-slot:label>
                        <div>Problem Classification</div>
                      </template>
                      <v-radio
                        v-for="item in classificationList"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>

                  <v-col
                    cols="12"
                  >
                  <v-radio-group
                      v-model="patientProblemDTO.problemManagementDiscipline"
                      row
                      :readonly="!isEdit"
                    >
                      <template v-slot:label>
                        <div>Problem Management Discipline</div>
                      </template>
                      <v-radio
                        v-for="item in disciplineList"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                  <v-radio-group
                      v-model="patientProblemDTO.problemCertainty"
                      row
                      :readonly="!isEdit"
                    >
                      <template v-slot:label>
                        <div>Problem Certainty</div>
                      </template>
                      <v-radio
                        v-for="item in certaintyList"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col
                    cols="6"
                  >
                  <v-radio-group
                      v-model="patientProblemDTO.problemPrognosis"
                      row
                      :readonly="!isEdit"
                    >
                      <template v-slot:label>
                        <div>Problem Prognosis</div>
                      </template>
                      <v-radio
                        v-for="item in prognosisList"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                  >
                  <v-radio-group
                      v-model="patientProblemDTO.problemStatus"
                      row
                      :readonly="!isEdit"
                    >
                      <template v-slot:label>
                        <div>Problem Status</div>
                      </template>
                      <v-radio
                        v-for="item in statusList"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                  >
                  <v-radio-group
                      v-model="patientProblemDTO.problemAwareness"
                      row
                      :readonly="!isEdit"
                    >
                      <template v-slot:label>
                        <div>Patient Problem Awareness</div>
                      </template>
                      <v-radio
                        v-for="item in problemAwareness"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="6"
                  >
                  <v-radio-group
                      v-model="patientProblemDTO.prognosisAwareness"
                      row
                      :readonly="!isEdit"
                    >
                      <template v-slot:label>
                        <div>Patient Prognosis Awareness</div>
                      </template>
                      <v-radio
                        v-for="item in prognosisAwareness"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    lg="4"
                  >
                    <v-menu
                      ref="menuref"
                      v-model="menuID"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template
                        v-slot:activator="{ on, attrs }"
                      >
                        <v-text-field
                          v-model="patientProblemDTO.identificationDate"
                          :prepend-icon="icons.mdiCalendar"
                          :readonly="!isEdit"
                          label="Identification Date"
                          hint="YYYY-MM-DD"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="patientProblemDTO.identificationDate"
                        :show-current="true"
                        color="primary"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="4"
                  >
                    <v-menu
                      ref="menuref"
                      v-model="menuON"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template
                        v-slot:activator="{ on, attrs }"
                      >
                        <v-text-field
                          v-model="patientProblemDTO.onsetDate"
                          :prepend-icon="icons.mdiCalendar"
                          :readonly="!isEdit"
                          label="Onset Date"
                          hint="YYYY-MM-DD"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="patientProblemDTO.onsetDate"
                        :show-current="true"
                        color="primary"
                        reactive
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                  >
                    <v-menu
                      ref="menuref"
                      v-model="menuER"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template
                        v-slot:activator="{ on, attrs }"
                      >
                        <v-text-field
                          v-model="patientProblemDTO.expectedResolutionDate"
                          :prepend-icon="icons.mdiCalendar"
                          :readonly="!isEdit"
                          label="Expected ResolutionDate Date"
                          hint="YYYY-MM-DD"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="patientProblemDTO.expectedResolutionDate"
                        :show-current="true"
                        color="primary"
                        reactive
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="6"
                  >
                  <v-textarea
                    outlined
                    v-model="patientProblemDTO.onsetComment"
                    label="OnSet Comments"
                    ></v-textarea>
                  </v-col>

                  <v-col
                    cols="3"
                  >
                    <v-text-field
                      v-model="patientProblemDTO.problemProbability"
                      type="number"
                      label="Probability"
                      hint="0-1, 1 = 100%"
                      :readonly="!isEdit"
                      :rules="[required]"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="3"
                  >
                    <v-switch
                    v-if="isEdit"
                    v-model="patientProblemDTO.isActive"
                    color="primary"
                    :readonly="!isEdit"
                    >
                    <template v-slot:label>
                      {{patientProblemDTO.isActive ? "Active" : "Inactive"}}
                    </template>
                    </v-switch>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      color="error"
                      type="reset"
                      text
                      @click="closeForm()"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      v-if="isEdit"
                      color="primary"
                      text
                      @click="submitForm()"
                    >
                      Save
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { mdiClose, mdiCalendar } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import problemService from '@/services/ProblemService'
import useProblemList from '@/store/useProblemService'
import moment from 'moment'
import ProblemSelect from '@/components/problem/ProblemSelect.vue'
import SeveritySelect from '@/components/problem/SeveritySelect.vue'
import VerificationSelect from '@/components/problem/VerificationSelect.vue'
import CommonList from '@/store/CommonService'

export default {
  components: {
    ProblemSelect,
    SeveritySelect,
    VerificationSelect,
  },
  model: {
    prop: 'isProblemFormActive',
    event: 'update:is-problem-form-active',
  },
  props: {
    isProblemFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    patientProblemDTO: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    console.log('Problem Add/Edit', props.patientProblemDTO, props.isEdit)

    const problemForm = ref(null)
    const menuID = ref(false)
    const menuER = ref(false)
    const menuON = ref(false)
    const menuref = ref(null)

    const validate = patientProblemDTO => {
      if (problemForm.value.validate(patientProblemDTO)) {
        return true
      }

      return false
    }

    const save = d => {
      menuref.value.save(d)
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update:is-problem-form-active', false)
    }

    const { formatDate } = CommonList()

    const PROBLEM_APP = 'app-problem'

    // Register module
    if (!store.hasModule(PROBLEM_APP)) {
      store.registerModule(PROBLEM_APP, problemService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROBLEM_APP)) store.unregisterModule(PROBLEM_APP)
    })

    const {
      postPatientProblem,
      statusList,
      classificationList,
      disciplineList,
      certaintyList,
      problemAwareness,
      prognosisList,
      prognosisAwareness,
    } = useProblemList()

    return {
      problemForm,
      validate,
      closeForm,
      refreshParent,


      postPatientProblem,
      statusList,
      classificationList,
      disciplineList,
      certaintyList,
      problemAwareness,
      prognosisList,
      prognosisAwareness,

      required,
      menuID,
      menuER,
      menuON,
      menuref,
      save,

      formatDate,

      icons: {
        mdiClose,
        mdiCalendar,
      },
    }
  },
  created(){
    console.log("created", this.patientProblemDTO)
    if (this.patientProblemDTO.id == undefined){
      this.patientProblemDTO.isActive = true
      this.patientProblemDTO.problemPriority = 16
      this.patientProblemDTO.identificationDate = moment(new Date()).format('YYYY-MM-DD')
      this.patientProblemDTO.onsetDate = moment(new Date()).format('YYYY-MM-DD')
      this.patientProblemDTO.expectedResolutionDate = moment(new Date()).format('YYYY-MM-DD')
      this.patientProblemDTO.problemPersistence = "acute"
      this.patientProblemDTO.problemStatus = "active"
      this.patientProblemDTO.conditionVerification = {display: "Confirmed"}
      this.patientProblemDTO.problemSeverity = {display: "Unknown"}

      this.patientProblemDTO.problemClassification = "admission"
      this.patientProblemDTO.problemManagementDiscipline = "medicine"
      this.patientProblemDTO.problemCertainty = "HI - high"
      this.patientProblemDTO.problemProbability = 1
      this.patientProblemDTO.problemAwareness = "full"
      this.patientProblemDTO.prognosisAwareness = "full"
      this.patientProblemDTO.problemPrognosis = "good"
    }
    else{
      this.patientProblemDTO.identificationDate = this.formatDate(this.patientProblemDTO.identificationDate)
      this.patientProblemDTO.onsetDate = this.formatDate(this.patientProblemDTO.onsetDate)
      this.patientProblemDTO.expectedResolutionDate = this.formatDate(this.patientProblemDTO.expectedResolutionDate)
    }
  },
  methods: {
    submitForm() {
      if (this.validate(this.patientProblemDTO)) {
        console.log('posting', this.patientProblemDTO)
        this.postPatientProblem(this.patientProblemDTO)

        this.closeForm()
        this.refreshParent()
      } else {
        console.log('Not Valid')
      }
    },
    updateProblem(problem) {
      console.log('new problem', problem)
      this.patientProblemDTO.problemDescriptionCode = problem.problemCode
      this.patientProblemDTO.problemLabel = problem.label
      console.log('problem update', this.patientProblemDTO)
    },
    updateSeverity(severity) {
      this.patientProblemDTO.problemSeverity = null
      this.patientProblemDTO.problemSeverityId = severity.value.id
      console.log('new severity', severity)
      console.log('severity update', this.patientProblemDTO)
    },
    updateVerification(verification) {
      this.patientProblemDTO.conditionVerification = null
      this.patientProblemDTO.conditionVerificationId = verification.value.id
      console.log('new Verification', verification.value)
      console.log('update', this.patientProblemDTO)
    },
  },
}
</script>
