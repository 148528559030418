<template>
  <div>
      <v-card>
        <v-card-title>
          <span class="text-h5">Vitals Details</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <template
              v-for="value, index in vitalOptions"
            >
              <v-col
                v-if="index != 'BloodSugarComments'"
                :key="index"
                cols="12"
                sm="12"
                xs="12"
                md="12"
                lg="6"
                xl="4"
              >
                <v-text-field
                  type="input"
                  v-model="vitalsData[index]"
                  dense
                  :label="index"
                  :hint="vitalUnit[index]"
                  :readonly="!isEdit"
                >
                  {{vitalUnit[index]}}
                </v-text-field>
              </v-col>
              <v-col
                v-else
                :key="index"
                cols="12"
                sm="12"
                xs="12"
                md="12"
                lg="6"
                xl="4"
              >
                <v-textarea
                  v-model="vitalsData[index]"
                  dense
                  :label="index"
                  outlined
                  :hint="vitalUnit[index]"
                  :readonly="!isEdit"
                >
                  {{vitalUnit[index]}}
                </v-textarea>
              </v-col>

            </template>
          </v-row>
        </v-card-text>
      </v-card>

    <single-confirmation
      v-model="isConfirmDialogActive"
      :selected-item="selectedItem"
      @confirmation="isConfirmDialogActive = false"
    ></single-confirmation>
  </div>
</template>

<script>
import { betweenValidator } from '@core/utils/validation'
import SingleConfirmation from '@/components/validation/SingleConfirmation.vue'

export default {
  components: { SingleConfirmation },
  props: {
    vitalsData: {
      type: Object,
      default: () => ({}),
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      isEdit: false,
      isConfirmDialogActive: false,
      selectedItem: null,
      vitals: null,
      vitalOptions: {
        Temperature: null,
        Pulse: null,
        Respirations: null,
        O2SaturationPercent: null,
        Systolic: null,
        Diastolic: null,
        Weight: null,
        Height: null,
        PrePeakFlow: null,
        PostPeakFlow: null,
        BloodSugar: null,
        BloodSugarComments: null,
      },
      vitalRec: {
        Temperature: { min: 94, max: 102 },
        Pulse: { min: 60, max: 100 },
        Respirations: { min: 11, max: 35 },
        O2SaturationPercent: { min: 84, max: 100 },
        Systolic: { min: 90, max: 180 },
        Diastolic: { min: 60, max: 120 },
        Weight: { min: 80, max: 650 },
        Height: { min: 35, max: 350 },
        PrePeakFlow: { min: 200, max: 550 },
        PostPeakFlow: { min: 200, max: 550 },
        BloodSugar: { min: 59, max: 356 },
      },
      vitalUnit: {
        Temperature: '°F',
        Pulse: 'bpm',
        Respirations: '/min',
        O2SaturationPercent: '%',
        Systolic: 'mmHg',
        Diastolic: 'mmHg',
        Weight: 'lbs',
        Height: 'in',
        PrePeakFlow: 'L/min',
        PostPeakFlow: 'L/min',
        BloodSugar: 'mg/dL',
        BloodSugarComments: null,
      },
    }
  },
  setup() {
    return {
      betweenValidator,
    }
  },
  mounted() {
    if ((this.vitalsData == null && this.vitalsData == undefined) || (this.vitalsData.Temperature == null && this.vitalsData.Pulse == null && this.vitalsData.Respirations == null && this.vitalsData.Systolic == null &&
        this.vitalsData.Diastolic == null && this.vitalsData.O2SaturationPercent == null && this.vitalsData.Weight == null &&  this.vitalsData.PrePeakFlow == null &&
        this.vitalsData.Height == null && this.vitalsData.PostPeakFlow == null && this.vitalsData.BloodSugar == null && this.vitalsData.BloodSugarComments == null))
      {
        this.vitals =  null
      }
    else {
      this.vitals = this.vitalsData
    }

    if (this.vitals != null) {
      this.isEdit = false
    } else {
      this.isEdit = true
      this.vitals = this.vitalOptions
    }
    console.log('Vitals Add Edit', this.vitalsData , this.vitals)

  },
}
</script>
