<template>
  <div id="error-list">

    <!-- Dialog -->
    <div
      v-if="selectedItem != null && renderDialog"
    >
      <div
        v-if="type == 'PatientForm'"
      >
        <v-dialog
          v-model="renderDialog"
          persistent
          max-width="75%"
        >
          <v-card
            v-if="!isEdit"
          >
            <v-card-text>
              <div>
                <v-row
                  justify="left"
                >
                  <v-col
                    md="auto"
                  >
                    <v-btn
                      color="error"
                      @click="updateDialog('render')"
                    >
                      Close
                    </v-btn>
                  </v-col>
                  <v-col
                    md="auto"
                  >
                    <router-link
                      :to="{ name: 'site-patient-chart', params: { patientId: selectedItem.patientId } }"
                    >
                      <v-btn
                        color="success"
                      >
                        View Chart
                      </v-btn>
                    </router-link>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <form-render
              :form-data="selectedItem.formStyle"
              :form-input-data="selectedItem.formInput"
              :patient-form-d-t-o="selectedItem"
              :is-edit="isEdit"
              :toolbar="isEdit"
              :show-addendum="isEdit"
              @update-dialog="updateDialog"
              @refetch-data="fetchSiteErrorMarks"
            ></form-render>
          </v-card>
        </v-dialog>
      </div>
      <div
        v-if="type == 'PatientAllergy'"
      >
      <allergy-add-edit
        v-model="renderDialog"
        :is-edit="isEdit"
        :patient-allergy-d-t-o="selectedItem"
        @refetch-data="fetchSiteErrorMarks"
      ></allergy-add-edit>
      </div>
      <div
        v-if="type == 'PatientProblem'"
      >
        <problem-add-edit
        v-model="renderDialog"
        :is-edit="isEdit"
        :patient-problem-d-t-o="selectedItem"
        @refetch-data="fetchSiteErrorMarks"
        ></problem-add-edit>
      </div>
      <div
        v-if="type == 'PatientImmunization'"
      >
        <immunization-add-edit
          v-model="renderDialog"
          :is-edit="isEdit"
          :patient-immunization-d-t-o="selectedItem"
          @refetch-data="fetchSiteErrorMarks"
        ></immunization-add-edit>
      </div>
      <div
        v-if="type == 'PatientVitals'"
      >
        <v-dialog
          v-model="renderDialog"
          persistent
          max-width="75%"
        >
          <v-card
            v-if="!isEdit"
          >
            <v-card-text>
              <div>
                <v-row
                  justify="left"
                >
                  <v-col
                    md="auto"
                  >
                    <v-btn
                      color="error"
                      @click="updateDialog('render')"
                    >
                      Close
                    </v-btn>
                  </v-col>
                  <v-col
                    md="auto"
                  >
                    <router-link
                      :to="{ name: 'site-patient-chart', params: { patientId: selectedItem.patientId } }"
                    >
                      <v-btn
                        color="success"
                      >
                        View Chart
                      </v-btn>
                    </router-link>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>

            <vitals-add-edit
              v-model="renderDialog"
              :is-edit="isEdit"
              :vitals-data="selectedItem"
              @refetch-data="fetchSiteErrorMarks"
            ></vitals-add-edit>
          </v-card>
        </v-dialog>
      </div>
      <div
        v-if="type == 'PatientDocument'"
      >
        <v-dialog
          v-model="renderDialog"
          persistent
          max-width="75%"
        >
          <v-card
            v-if="!isEdit"
          >
            <v-card-text>
              <div>
                <v-row
                  justify="left"
                >
                  <v-col
                    md="auto"
                  >
                    <v-btn
                      color="error"
                      @click="updateDialog('render')"
                    >
                      Close
                    </v-btn>
                  </v-col>
                  <v-col
                    md="auto"
                  >
                    <router-link
                      :to="{ name: 'site-patient-chart', params: { patientId: selectedItem.patientId } }"
                    >
                      <v-btn
                        color="success"
                      >
                        View Chart
                      </v-btn>
                    </router-link>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>

            <v-card>
              <div>
                <hr />
                <VuePdfEmbed :source="selectedItem.document" />
              </div>
            </v-card>
          </v-card>
        </v-dialog>
      </div>
      <div
        v-if="type == 'PatientTuberculosis'"
      >
        <v-dialog
          v-model="renderDialog"
          persistent
          max-width="75%"
        >
          <v-card
            v-if="!isEdit"
          >
            <patient-tuberculosis-add-edit
              v-model="renderDialog"
              :is-edit="isEdit"
              :patient-tuberculosis-d-t-o="selectedItem"
              @refetch-data="fetchSiteErrorMarks"
            ></patient-tuberculosis-add-edit>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="error-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <v-combobox
        v-model="selectedType"
        :items="typeList"
        outlined
        label="Select Type"
        clearable
        dense
      >
      </v-combobox>

      <v-spacer></v-spacer>

      <patient-select
        @patient-select="getPatientErrorMarks"
      ></patient-select>

      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedItem"
        :headers="tableColumns"
        :items="errorMarkList"
        :options.sync="options"
        :search="searchQuery"
        :loading="loading"
      >
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-btn
            text
            x-small
            @click="selectItem(item), updateDialog(null)"
          >
            <v-icon
              size="20"
              class="me-2"
            >
              {{ icons.mdiFileDocumentOutline }}
            </v-icon>
            <span>Details</span>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
} from '@mdi/js'

import errorMarkService from '@/services/ErrorMarkService'
import useErrorMarkService from '@/store/useErrorMarkService'

import PatientSelect from '@/components/patient/PatientSelect.vue'
import FormRender from '@/apps/form/FormRender.vue'
import AllergyAddEdit from '@/views/allergy/PatientAllergyAddEdit.vue'
import ProblemAddEdit from '@/views/problem/PatientProblemAddEdit.vue'
import ImmunizationAddEdit from '@/views/immunization/PatientImmunizationAddEdit.vue'
import VitalsAddEdit from '@/views/vitals/PatientVitalsAddEdit.vue'
import PatientTuberculosisAddEdit from '@/views/tuberculosis/PatientTuberculosisAddEdit.vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

import CommonList from '@/store/CommonService'

export default {
  components: {
    PatientSelect,
    FormRender,
    AllergyAddEdit,
    ProblemAddEdit,
    ImmunizationAddEdit,
    VitalsAddEdit,
    PatientTuberculosisAddEdit,
    VuePdfEmbed,
  },
  data: () => ({
    type: null,
    read: true,
  }),
  setup() {
    const { formatDate } = CommonList()

    const ERROR_APP = 'app-error'

    // Register module
    if (!store.hasModule(ERROR_APP)) {
      store.registerModule(ERROR_APP, errorMarkService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ERROR_APP)) {
        store.unregisterModule(ERROR_APP)
      }
    })

    const {
      tableColumns,
      options,
      loading,
      searchQuery,

      typeList,
      selectedType,

      errorMarkList,
      fetchSiteErrorMarks,
      fetchPatientErrorMarks,
    } = useErrorMarkService()

    const isEdit = ref(false)
    const selectedItem = ref()
    const isErrorMarkFormActive = ref(false)
    const renderDialog = ref(false)

    return {
      errorMarkList,
      tableColumns,
      searchQuery,
      loading,
      options,

      typeList,
      selectedType,

      selectedItem,
      isErrorMarkFormActive,
      isEdit,
      renderDialog,

      fetchSiteErrorMarks,
      fetchPatientErrorMarks,

      formatDate,
      icons: {
        mdiAccountOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
      },
    }
  },
  mounted() {
    this.fetchSiteErrorMarks()

    this.$watch('errorMarkList', () => {
      console.log('errorMarkList', this.errorMarkList)
    })

  },
  methods: {
    selectItem(item) {
      this.selectedItem = item.entityJSON
      this.type = item.type

      console.log('Error', this.selectedItem)
    },
    getPatientErrorMarks(patientDTO){
      this.fetchPatientErrorMarks(patientDTO.id)
    },
    updateDialog(dialog) {
      if (dialog === 'render') {
        if (this.renderDialog) {
          this.selectedItem = {}
        }
        if (this.selectedItem == null) {
          this.renderDialog = false
        }
        this.renderDialog = !this.renderDialog
      }
      else{
        this.renderDialog = !this.renderDialog
      }

    },
  },
}
</script>
