<template>
  <div id="immunization-select">
    <v-combobox
      v-model="selectedImmunization"
      :items="immunizationList"
      item-text="useUnitGenericName"
      outlined
      label="Select Immunization"
      clearable
      :readonly="readonly"
      dense
      @click="log(immunization)"
      @change="selectImmunization(selectedImmunization)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import medicationService from '@/services/MedicationService'
import useMedicationService from '@/store/useMedicationService'

export default {
  props: {
    immunization: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const MEDICATION_APP = 'app-medication'

    // Register module
    if (!store.hasModule(MEDICATION_APP)) {
      store.registerModule(MEDICATION_APP, medicationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEDICATION_APP)) {
        store.unregisterModule(MEDICATION_APP)
      }
    })

    const { immunizationList, fetchImmunizations } = useMedicationService()

    const selectedImmunization = ref()

    const log = msg => {
      console.log(msg)
    }

    const selectImmunization = () => {
      emit('immunization-select', selectedImmunization.value)
    }

    return {
      immunizationList,
      selectedImmunization,
      fetchImmunizations,

      required,
      log,

      selectImmunization,
    }
  },
  mounted() {
    this.fetchImmunizations()

    this.$watch('immunizationList', () => {
      if (this.immunization != null) {
        this.immunizationList.forEach(type => {
          if (type.id == this.immunization){
            this.selectedImmunization = type
          }
        })
        this.selectImmunization()
      }
    })
  },
}
</script>
