<template>
  <div id="verification-select">
    <v-combobox
      key="id"
      v-model="selectedVerification"
      :items="verificationList"
      item-text="display"
      :readonly="readonly"
      outlined
      label="Select Verification"
      clearable
      dense
      @change="selectVerification(selectedVerification)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import problemService from '@/services/ProblemService'
import useProblemService from '@/store/useProblemService'

export default {
  props: {
    verification: {
      type: Object,
      default: () => ({}),
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const PROBLEM_APP = 'app-problem'

    // Register module
    if (!store.hasModule(PROBLEM_APP)) {
      store.registerModule(PROBLEM_APP, problemService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROBLEM_APP)) {
        store.unregisterModule(PROBLEM_APP)
      }
    })

    const { verificationList, fetchVerifications } = useProblemService()

    const selectedVerification = ref(props.verification.display)

    const selectVerification = () => {
      emit('verification-select', selectedVerification)
    }

    return {
      verificationList,
      selectedVerification,
      fetchVerifications,

      required,

      selectVerification,
    }
  },
  mounted() {
    this.fetchVerifications()
    this.$watch('verificationList', () => {
      if (this.verification != null && this.verification !== undefined) {
        this.verificationList.forEach(verification => {
          if ((verification.display === this.verification.display) || (verification.id === this.verification.Id)) {
            this.selectedVerification = verification
          }
        })
    }
      this.selectVerification()
    })
  },
}
</script>
